$maximum: 1200px;
$desktop: 920px;
$tablet: 768px;
$mobile: 540px;

$container-padding: 24px;

$dark-theme-color: #6fcff0;
$theme-color: #61dafb;
$black-color: #212121;
$black-button-color: #36393f;
$black-button-color-hover: #20232a;
$real-theme-color: #1aa7f9;
