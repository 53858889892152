.cropper-event-notification {
	background: rgba(0, 0, 0, 0.6);
	color: white;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 20px;
	transition: opacity 1s;
	opacity: 0;
	pointer-events: none;
	padding-left: 50px;
	padding-right: 50px;
	&--visible {
		transition: opacity 0.25s;
		pointer-events: all;
		opacity: 1;
	}
}
