@import '../scssVariables/mixins.scss';

.image-editor-navigation {
	background: #1b1a21;
	height: 65px;
	border-top: solid 1px #2b2a30;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 16px;
	padding-right: 16px;
	&__button {
		margin-inline-start: 8px;
		margin-inline-end: 8px;

		@include small-mobile() {
			margin-inline-start: 4px;
			margin-inline-end: 4px;
		}
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__upload-input {
		display: none;
	}

	@include small-mobile() {
		padding-left: 8px;
		padding-right: 8px;
	}
}

//

.rmc-navigation {
	display: flex;
	align-items: center;
	padding: 20px 15px;
	&__rotator {
		width: 100%;
		margin-inline-start: 10px;
		margin-inline-end: 10px;
	}
	&__button {
		cursor: pointer;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin: 0 5px;
		background: none;
		border: none;
		outline: none;
		transition: transform 0.5s;
		padding: 0;
		&:hover,
		&:focus {
			transform: scale(1.1);
		}
	}
}

//zoom
.absolute-zoom-cropper-navigation {
	&__button {
		will-change: transform;
		cursor: pointer;
		width: 80px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		border: none;
		outline: none;
		transition: transform 0.5s;
		padding: 0;

		&:hover,
		&:focus {
			transform: scale(1.1);
		}
	}
}
