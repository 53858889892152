@import './scssVariables/constants.scss';

.advanced-cropper {
	max-height: 80vh;
	background-color: #2b2a30;
}
.image-editor {
	width: 100%;
	color: #61dafb;
	color: $theme-color;
	border: solid 1px #2b2a30;
	&__preview {
		height: 45px;
		width: 45px;
		// background: black;
		border: solid 1px #2b2a30;
		position: absolute;
		left: 20px;
		top: 20px;
		border-radius: 50%;
	}
	&__cropper {
		// background: #0f0e13;
		// padding: 0 2rem;
		// height: 60vh;
		// max-height: 80vh;
		// @media (min-width: 500px) {
		// 	// height: 500px;
		// }
		position: relative;
	}
	&__slider {
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		bottom: 20px;
		position: absolute;
	}
	&__cropper-overlay {
		transition: 0.5s;
		&--faded {
			color: rgba(black, 0.9);
		}
	}
	&__service {
		pointer-events: none;
		left: -100%;
		position: absolute;
	}
	&__reset-button {
		position: absolute;
		right: 6px;
		top: 20px;
		background: rgba(white, 0.1);
		&:hover {
			fill: $theme-color;
			background: rgba(white, 0.2);
		}
		&--hidden {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.preview {
	border: dashed 2px rgba(255, 255, 255, 0.764);
	border-radius: 50%;
}

.image-editor__preview {
	$preview-height: 150px;
	height: $preview-height;
	width: $preview-height - ($preview-height * 19.2px) / 100px;
	box-shadow: 0px 0px 4px #6dccfa;
}
